<div class="my-3 my-md-6" *ngIf="product">
	<div class="grid justify-content-center align-items-center">
		<div class="col-12 col-md-6 text-center">
			<p-image
				imageClass="max-w-full"
				[src]="product.image"
				[alt]="product.name"
				[preview]="true"
				(onShow)="showingImage()"
				(onHide)="hidingImage()"
				></p-image>
		</div>
		<div class="col-12 col-md-6 p-no-margin  py-3 px-0 py-md-0 px-md-3" [innerHTML]="product.description | html"></div>
	</div>
</div>
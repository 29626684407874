import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { SLIDE_IN_ANIMATION_2, SLIDE_OUT_ANIMATION_2, triggerChildAnimation, verticalCollapseAnimation } from 'src/app/utils/animations';

export const enum X {
	left = 'left',
	center = 'center',
	right = 'right',
}
export const enum Y {
	top = 'top',
	center = 'center',
	bottom = 'bottom',
}

export const enum TOAST_TYPES {
	info = 'info',
	error = 'error',
	success = 'success',
	warning = 'warning',
}

export interface IToast {
	title: string;
	subtitle: string;
	visible: boolean;
	icon?:string;
	close?:boolean;
	type?:TOAST_TYPES;
	x?: X;
	y?: Y;
}

@Component({
	selector: 'm-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss'],
	animations: [
		SLIDE_IN_ANIMATION_2,
		SLIDE_OUT_ANIMATION_2,
		verticalCollapseAnimation,
		triggerChildAnimation,
	],
})
export class ToastComponent implements OnInit {
	public title = 'Title';
	public subtitle = 'Subtitle';
	public icon;
	public close = true;
	public type;
	public x = X.right;
	public y = Y.top;
	public visible = false;

	public classes;

	constructor() {
		// const x = left	center	right
		// const y = top	center	bottom
	}

	ngOnInit(): void {
		this[`${this.type}Toast`]();
	}

	infoToast() {
		this.icon = 'pi-info-circle';
	}

	successToast() {
		this.icon = 'pi-check-circle';
	}

	errorToast() {
		this.icon = 'pi-times-circle';
	}

	warningToast() {
		this.icon = 'pi-exclamation-triangle';
	}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

// PRIME NG
import { GMapModule } from 'primeng/gmap';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { ToolbarModule } from 'primeng/toolbar';
import { EditorModule } from 'primeng/editor';
import { RippleModule } from 'primeng/ripple';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SidebarModule } from 'primeng/sidebar';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ImageModule } from 'primeng/image';
import { ProgressBarModule } from 'primeng/progressbar';
import { TooltipModule } from 'primeng/tooltip';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { CustomDialogComponent } from './custom-dialog/custom-dialog.component';
import { CardComponent } from './card/card.component';
import {RadioButtonModule} from 'primeng/radiobutton';

// CUSTOM
import { NavbarComponent } from './navbar/navbar.component';
import { WhoComponent } from './who/who.component';
import { ProductsComponent } from './products/products.component';
import { WhereComponent } from './where/where.component';
import { AccordionComponent } from './accordion/accordion.component';
import { EditorComponent } from './editor/editor.component';
import { PicSelectorComponent } from './pic-selector/pic-selector.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastComponent } from './toast/toast.component';
import { LanguageBlockComponent } from './language-block/language-block.component';
import { BubbleComponent } from './bubble/bubble.component';
import { StickyContainerComponent } from './sticky-container/sticky-container.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { PicComponent } from './pic/pic.component';
import { BannerNewComponent } from './banner-new/banner-new.component';
import { SpinnerComponent } from './spinner/spinner.component';

import { FooterComponent } from './footer/footer.component';



const PRIME = [
	GMapModule,
	InputTextModule,
	CardModule,
	ButtonModule,
	AccordionModule,
	ToolbarModule,
	EditorModule,
	RippleModule,
	CheckboxModule,
	SidebarModule,
	ConfirmDialogModule,
	DynamicDialogModule,
	InputSwitchModule,
	ImageModule,
	ProgressBarModule,
	TooltipModule,
	ToggleButtonModule,
	BreadcrumbModule,
	DropdownModule,
	TableModule,
	MessagesModule,
	MessageModule,
	ProgressSpinnerModule,
	DialogModule,
	RadioButtonModule
];

const COMPONENTS = [
	NavbarComponent,
	WhoComponent,
	ProductsComponent,
	WhereComponent,
	PicSelectorComponent,
	AccordionComponent,
	EditorComponent,
	LanguageBlockComponent,
	BubbleComponent,
	StickyContainerComponent,
	ProductDetailComponent,
	ProductCardComponent,
	PicComponent,
	BannerNewComponent,
	SpinnerComponent,
	CustomDialogComponent,
	CardComponent,
	FooterComponent
];

const DYNAMIC = [ToastComponent];
@NgModule({
	declarations: [...COMPONENTS, ...DYNAMIC ],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, PipesModule, TranslateModule, ...PRIME],
	providers: [ConfirmationService, MessageService],
	exports: [...PRIME, ...COMPONENTS],
})
export class ComponentsModule {}

import { Component, OnInit, Input } from '@angular/core';
import { SLIDE_IN_ANIMATION } from 'src/app/utils/animations';

const t = 300;
@Component({
	selector: 'm-accordion',
	templateUrl: './accordion.component.html',
	styleUrls: ['./accordion.component.scss'],
	animations: [SLIDE_IN_ANIMATION]
})
export class AccordionComponent {
	@Input() title;

	@Input() level:number = 1;
	@Input() bottomClose:boolean = false;

	toggle = false;

	levels= [
		'', '', 'md', 'sm'
	]

	constructor() {}
}

<div id="container"
class="flex  bg-light-gray shadow-6 text-justify p-2 align-items-top w-full"
	[ngClass]="[x, y, type, subtitle ? 'align-items-top' : 'align-items-center']"
	*ngIf="visible"
	@slideIn
    @slideOut>
	<div class="px-3 py-2">
		<i class="pi" [ngClass]="icon"></i>
	</div>
	<div clas="flex flex-column w-full align-items-center">
		<div class="flex justify-content-between align-items-center">
			<div class="">
				{{title | translate}}
			</div>
			<div *ngIf="close">
				<button pButton pRipple type="button" icon="pi pi-times"
					class="cursor-pointer p-button-text p-button-rounded p-button-sm"></button>
			</div>

		</div>
		<div class="pr-3 py-1" *ngIf="subtitle">
			{{subtitle | translate}}
		</div>

	</div>
</div>
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { CustomDialogComponent } from '../components/custom-dialog/custom-dialog.component';
import { ToastComponent } from '../components/toast/toast.component';
import { DynamicDirective } from '../utils/dynamic.directive';

@Injectable({
	providedIn: 'root',
})
export class InjectComponentService {
	@ViewChild(DynamicDirective, { static: true }) adHost: DynamicDirective;

	private componentMapper = {
		toast: ToastComponent,
		dialog: CustomDialogComponent
	};

	constructor(private resolver: ComponentFactoryResolver, private applicationRef: ApplicationRef, private injector: Injector) {}

	public show(type, data) {
		const componentRef = this.resolver.resolveComponentFactory(this.componentMapper[type]).create(this.injector);
		this.applicationRef.attachView(componentRef.hostView);


		Object.keys(data).forEach((key) => {
			componentRef.instance[key] = data[key];
		});

		const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

		document.body.appendChild(domElem);

		return componentRef;

	}

	removeComponentFromBody(componentRef: ComponentRef < any > ) {
		this.applicationRef.detachView(componentRef.hostView);
		componentRef.destroy();
	  }
}

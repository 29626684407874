import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SLIDE_IN_ANIMATION_2, SLIDE_OUT_ANIMATION_2, verticalCollapseAnimation, triggerChildAnimation } from 'src/app/utils/animations';

@Component({
	selector: 'm-product-card',
	templateUrl: './product-card.component.html',
	styleUrls: ['./product-card.component.scss'],
	animations: [SLIDE_IN_ANIMATION_2, SLIDE_OUT_ANIMATION_2, verticalCollapseAnimation, triggerChildAnimation],
})
export class ProductCardComponent implements OnInit {
	@Input() item;
	@Output() onClick = new EventEmitter<any>();

	constructor() {}

	ngOnInit(): void {}

	openItem(){
		this.onClick.emit(this.item);
	}
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
	selector: 'm-custom-dialog',
	templateUrl: './custom-dialog.component.html',
	styleUrls: ['./custom-dialog.component.scss'],
})
export class CustomDialogComponent implements OnInit {
	public visible = false;
	public header = '';
	public body = '';
	public isBoolean;
	public showHeader: boolean;
	public closable = true;
	public position: 'center' | 'top' | 'bottom' | 'left' | 'right' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' = 'bottom';
	public labelAccept;
	public labelReject;

	@Output() onResolve = new EventEmitter<boolean | any>();

	constructor() {}

	ngOnInit(): void {
		this.showHeader = !!this.header;
		this.labelAccept = this.labelAccept ||  'label.yes';
		this.labelReject = this.labelReject || 'label.no';
	}

	resolve(value?) {
		this.visible = !this.visible;
		this.onResolve.emit(value);
	}

	close() {}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from './components/components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from "@angular/fire/auth";
import {
	AngularFirestoreModule, AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { environment } from 'src/environments/environment';
import { PipesModule } from './pipes/pipes.module';
import { UtilsModule } from './utils/utils.module';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  }

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		ComponentsModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		PipesModule,
		TranslateModule.forRoot({
			defaultLanguage: 'es',
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			  }
		}),
		AngularFireModule.initializeApp(environment.firebase),
		AngularFirestoreModule,
		AngularFirestoreModule.enablePersistence(),
		AngularFireAuthModule,
		UtilsModule
	],
	exports: [
		ComponentsModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		PipesModule
	]
})
export class SharedModule { }

<div id="main">
		<div  *ngFor="let bubble of bubbles"
			class="my-1 cursor-pointer"
			[ngClass]="bubble.classes"
			[pTooltip]="bubble.label | translate"
			(click)="openLink(bubble)">

			<m-pic [image]="bubble.image" class="w-full"></m-pic>
			<span class="hidden" [innerHTML]="bubble.att"></span>
		</div>
</div>

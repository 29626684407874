<a class="hiddenLink" [id]="id"></a>

<h2 class="sectionTitle"> CONTACTO </h2>
<m-spinner>

<div id="container" class="container admin" *ngIf="where">
		<div id="card" #card >
			<div
				class="grid justify-content-start align-items-top md:align-items-center p-2"
				[ngStyle]="{'height.px': cardHeight}">
				<div class="col-12 col-md-8 col-lg-5 p-3 p-md-4 shadow-4" id="direction">
					<div class="grid">

						<div class="col-6 flex align-items-top" *ngFor="let phone of where?.phones">
							<i class="pi pi-phone pt-1"></i>
							<span class="px-2">
								<span class="text-primary">
									{{phone.label}}
								</span>
								<br>
								{{phone.number}}
							</span>
						</div>
					</div>

					<div class="grid mt-3">
						<div class="col-12 flex align-items-top" *ngFor="let email of where?.emails">
							<i class="pi pi-envelope pt-1"></i>
							<span class="px-2">
								<span class="text-primary">
									{{email.label}}
								</span>
								<br>
								{{email.address}}
							</span>
						</div>
					</div>

					<div class="flex align-items-top mt-3">
						<i class="pi pi-map-marker pt-1"></i>
						<div class="px-2">
							<div class="p-no-margin" [innerHTML]="where?.address"></div>
							<a [href]="where?.location?.url" target="blank">
								<small>
									{{'label.seeInMaps' | translate }}
								</small>
							</a>

						</div>

					</div>
					<div class="flex align-items-top">


					</div>
				</div>
			</div>
		</div>
	</div>


	<p-gmap
	*ngIf="mapLoaded"
	id="map"
	[options]="gmap.options"
	[overlays]="gmap.overlays"
	[style]="gmap.styles"></p-gmap>
</m-spinner>
import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';

import { GMAP_OPTIONS, COMPONENTS_ID } from 'src/app/constants';
import { InfoService } from 'src/app/services/info.service';
import { isMobile, isResponsive } from 'src/app/utils/utils';

declare var google: any;
const HEIGHT = 400;
const MOVEMENT = 1.5;
@Component({
	selector: 'm-where',
	templateUrl: 'where.component.html',
	styleUrls: ['./where.component.scss'],
})
export class WhereComponent implements OnInit {
	@ViewChild('card') cardView: ElementRef;
	@ViewChildren('card', { read: ElementRef }) cardElement: QueryList<ElementRef>;

	public id = COMPONENTS_ID['WHERE'];
	public where;
	public mapHeight = HEIGHT + 100;
	public cardHeight = HEIGHT;
	public gmap: any = {};
	public mapLoaded = false;

	constructor(private infoSrv: InfoService) {}

	ngOnInit(): void {
		this.infoSrv.getInfo().subscribe((values) => {
			values.address = values.address.replace('<p>', '<span>').replace('</p>', '</span>');
			this.where = values;
			setTimeout(() => {
				this.checkHeight();
				this.setGmap();
			}, 100);
		});
	}

	checkHeight() {
		const h = this.cardView.nativeElement.scrollHeight;
		const h2 = h + 20;
		const hMobile = h * (MOVEMENT + 0.5);

		this.mapHeight = isMobile() ? hMobile : h2;
		this.cardHeight = isResponsive() ? h : h2;
	}

	setGmap() {
		const { coordinates = {}, sm, md, lg, zoom } = this.where?.location || {};

		this.gmap.styles = {
			width: '100%',
			height: `${this.mapHeight}px`,
		};

		const moveH = isMobile() ? 0 : isResponsive() ? md : lg;
		const moveV = (isMobile() ? sm : 0) * MOVEMENT;

		this.gmap.options = null;

		this.gmap.options = {
			...GMAP_OPTIONS,
			center: { lat: coordinates.lat + moveV, lng: coordinates.lng + moveH },
			zoom,
		};

		this.gmap.overlays = [
			new google.maps.Marker({
				position: {
					lat: coordinates.lat,
					lng: coordinates.lng,
				},
				title: 'Multiplas',
			}),
		];

		this.mapLoaded = true;
	}

	openMaps() {
		const url = this.where?.location?.url;
		window.open(url, '_blank');
	}
}

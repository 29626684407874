<div id="main" class="flex flex-column w-full transition-all transition-duration-200"
	 [ngClass]="{'my-3': toggle || margin }">
	<div id="header" class="w-full cursor-pointer " (click)="toggle = !toggle"
		[ngStyle]="{'top.px': top}">
		<ng-content select="[header]"></ng-content>
	</div>
	<div class="w-full" *ngIf="toggle" [@slideIn]>
		<ng-content></ng-content>
		<div
			*ngIf="bottomClose"
			class="text-center cursor-pointer p-0"
			(click)="toggle=!toggle" >
			<span class="pi pi-angle-up"></span>
		</div>
	</div>
</div>
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InjectComponentService } from './inject-component.service';

@Injectable({
	providedIn: 'root',
})
export class DialogService {
	constructor(private resolver: InjectComponentService) {}

	openTimerDialog(header, body, time = 5000, position = 'center') {
		return this.showDialog(
			{
				header,
				body,
				position,
				isBoolean: false,
			},
			time
		);
	}

	openInformativeDialog(header, body) {
		return this.showDialog({
			header,
			body,
			position: 'center',
			isBoolean: false,
		});
	}

	openDialog(header, body): Observable<any> {
		return this.showDialog({ header, body, isBoolean: false });
	}

	openSelectionDialog(header, body, position = 'center', labelAccept?, labelReject?): Observable<boolean> {
		return this.showDialog({
			header,
			body,
			position,
			isBoolean: true,
			closable: false,
			labelAccept,
			labelReject,
		});
	}

	private showDialog(data, time?) {
		const componentRef = this.resolver.show('dialog', Object.assign(data));

		componentRef.instance['visible'] = true;

		return componentRef.instance['onResolve'].pipe(
			map((values) => {
				this.resolver.removeComponentFromBody(componentRef);

				return values;
			})
		);

		if (time) {
			setTimeout(() => {
				componentRef.instance['visible'] = false;
			}, time);
			setTimeout(() => {
				this.resolver.removeComponentFromBody(componentRef);
			}, time + 300);
		}
	}
}

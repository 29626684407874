import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './safe-html.pipe';
import { FilterPipe } from './filter.pipe';

const PIPES = [
	SafeHtmlPipe,
	FilterPipe
]

@NgModule({
	declarations: [
		...PIPES
	],
	imports: [
		CommonModule
	],
	exports: [
		...PIPES
	]
})
export class PipesModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { COMPONENTS_ID } from 'src/app/constants';
import { isMobile } from 'src/app/utils/utils';

@Component({
	selector: 'm-navbar',
	templateUrl: 'navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
	public display = false;
	public sidebarModal = false;

	public options = [
		{ id: COMPONENTS_ID.WHO, label: 'Quienes somos' },
		{ id: COMPONENTS_ID.PRODUCTS, label: 'Productos' },
		{ id: COMPONENTS_ID.WHERE, label: 'Contacto' },
		{ id: COMPONENTS_ID.LOGIN, label: 'Area privada' },
	];

	constructor(private router: Router) {}

	ngOnInit(): void {}

	navigate(id) {
		if (isMobile()) {
			this.changeDisplay();
		}

		if (id === 'login') {
			return this.router.navigate(['login']);
		}

		this.router.navigate([], { fragment: id });
	}

	changeDisplay() {
		this.display = !this.display;
		// this.sidebarModal = !this.sidebarModal;
	}
}

import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'm-banner-new',
	templateUrl: './banner-new.component.html',
	styleUrls: ['./banner-new.component.scss'],
})
export class BannerNewComponent implements OnInit {
	public items = [];
	constructor() {}

	ngOnInit(): void {}
}

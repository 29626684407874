<a class="hiddenLink" [id]="id"></a>

<m-spinner>
	<div class="container" *ngIf="categories.length">
		<h2 class="sectionTitle"> PRODUCTOS </h2>
		<div class="w-full mb-4 block sm:hidden">
			<small>
				Categoría
			</small>
			<p-dropdown [options]="categories" [(ngModel)]="selectedCategory" optionLabel="name" class="w-full big"
				panelStyleClass="big"></p-dropdown>

		</div>

		<div class="grid justify-content-center align-items-stretch hidden sm:flex">
			<div *ngFor="let category of categories | filter : { 'isVisible' : true }"
				class="col-6 col-md-4 col-lg-3 p-2 ">

				<button
					pButton
					pRipple
					class="p-button-raised w-full h-full"
					[ngClass]="{'p-button-text shadow-4':selectedCategory.id === category.id}"
					type="button"
					[label]="category.name"
					(click)="changeCategory(category)"></button>
			</div>
		</div>

		<div id="productContainer" class="grid justify-content-center align-items-center align-content-center mb-5 ">
			<div *ngFor="let product of selectedCategory?.products | filter : { 'isVisible' : true } as result "
				class="col-6 col-sm-6 col-md-4 col-lg-3 align-self-stretch px-1 px-sm-2 ">

				<m-product-card [item]="product" (onClick)="openProduct($event)"></m-product-card>
			</div>

		</div>
	</div>
</m-spinner>
<div id="mainBanner"
	class="p-2 p-md-5"
	style=" background-image: url('/assets/images/headerImg.png');">

	<div class="grid justify-content-end align-content-center flex-wrap h-full" >
		<div class="col-8 col-md-4 p-1" *ngFor="let item of items">
			<div class="bg-white p-2 border-round  shadow-5">
				<m-pic image="logo_white_bg_blue"></m-pic>
				<h3 class="my-3">
					{{item.title}}
				</h3>
				<p>
					{{item.description}}
				</p>
			</div>
			<div>

			</div>
		</div>

	</div>
</div>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
	selector: 'm-pic-selector',
	templateUrl: './pic-selector.component.html',
	styleUrls: ['./pic-selector.component.scss'],
})
export class PicSelectorComponent implements OnInit {
	@Input() src;

	@Input() field = 'image';

	@Input() form = new FormGroup({
		image: new FormControl(null, Validators.required),
	});

	@Output() onFileChange = new EventEmitter<any>();

	public file;
	public mensajeArchivo = 'No hay un archivo seleccionado';
	public datosFormulario = new FormData();
	public fileName = '';
	public URLPublica = '';
	public porcentaje = 0;
	public finalizado = false;

	constructor(private firebaseSrv: FirebaseService) {}

	ngOnInit(): void {
		this.src = this.form.value[this.field];
	}

	//Evento que se gatilla cuando el input de tipo archivo cambia
	public cambioArchivo(event) {
		if (event.target.files.length > 0) {
			const file = (event.target as HTMLInputElement).files[0];
			this.form.value[this.field] = file;

			this.form.patchValue({
			  img: file // no lo toques cojones
			});

			this.form.get(this.field).updateValueAndValidity()

			const reader = new FileReader();
			reader.onload = () => {
			  this.src = reader.result as string;
			}
			reader.readAsDataURL(file)

			this.file = file;
			this.onFileChange.emit(file);
		} else {
			this.mensajeArchivo = 'No hay un archivo seleccionado';
		}
	}

	//Sube el archivo a Cloud Storage
	public subirArchivo() {
		const file = this.file;
		const referencia = this.firebaseSrv.cloudStorageReference(this.fileName);
		const tarea = this.firebaseSrv.cloudStorageUpload(this.fileName, file);

		//Cambia el porcentaje
		tarea.percentageChanges().subscribe((porcentaje) => {
			this.porcentaje = Math.round(porcentaje);
			if (this.porcentaje == 100) {
				this.finalizado = true;
			}
		});

		referencia.getDownloadURL().subscribe((URL) => {
			this.URLPublica = URL;
		});
	}

	public reset(){
		this.src = null;
	}
}

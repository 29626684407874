<div class="flex align-items-center">
	<div style="max-width: 3rem" class="mr-4">
		<p-image src="https://www.countryflags.com/wp-content/uploads/spain-flag-png-large.png" imageClass="max-w-full"></p-image>
	</div>
	<div>
		{{language.id}}
	</div>
</div>

<hr>
<ng-content>	</ng-content>
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	firebase: {
		apiKey: "AIzaSyDt77I09lWYLgif0xSnavX8Gi-C5E5YIf0",
		authDomain: "multiplas-sl.firebaseapp.com",
		databaseURL: "https://multiplas-sl.firebaseio.com",
		projectId: "multiplas-sl",
		storageBucket: "multiplas-sl.appspot.com",
		messagingSenderId: "760561005108",
		appId: "1:760561005108:web:5abbea7925a6c0e3f9d061",
		measurementId: "G-R82GMCPZHS"
	},
	analytics: {
		enabled: false
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

const MOBILE_BREAKPOINT = 425;
const TABLET_BREAKPOINT = 768;

export enum DIALOG_VIEWS {
	category,
	product,
}

export function getRandom(array) {
	return array[Math.floor(Math.random() * array.length)];
}

export function isMobile(size: number = window.innerWidth): boolean {
	return size <= MOBILE_BREAKPOINT;
}

export function isTablet(size: number = window.innerWidth): boolean {
	return size > MOBILE_BREAKPOINT && size <= TABLET_BREAKPOINT;
}

export function isResponsive(size: number = window.innerWidth): boolean {
	return size <= TABLET_BREAKPOINT;
}

export function generateId(str): string {
	const accentsMap = {
		a: 'á|à|ã|â|À|Á|Ã|Â',
		e: 'é|è|ê|É|È|Ê',
		i: 'í|ì|î|Í|Ì|Î',
		o: 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
		u: 'ú|ù|û|ü|Ú|Ù|Û|Ü',
		c: 'ç|Ç',
		n: 'ñ|Ñ',
	};
	const cleanStr = (str) =>
		Object.keys(accentsMap).reduce((acc, cur) => acc.replace(new RegExp(accentsMap[cur], 'g'), cur), str.replaceAll('[^a-zA-Z0-9]', ''));

	return removeSpaces(cleanStr(str)).toLowerCase();
}

export function numberId(id: number): string {
	let result = id.toString();
	const MAX_LENGTH = 3;
	const len = result.length;
	const addition = MAX_LENGTH - len;

	for (let i = 0; i < addition; i++) {
		result = `0${result}`;
	}

	return result;
}

export function removeSpaces(text) {
	return text.replace(/\s/g, '');
}

export function deepEqual(x, y) {
	if (x === y) {
		return true;
	} else if (typeof x == 'object' && x != null && typeof y == 'object' && y != null) {
		if (Object.keys(x).length != Object.keys(y).length) return false;

		for (var prop in x) {
			if (y.hasOwnProperty(prop)) {
				if (!deepEqual(x[prop], y[prop])) return false;
			} else return false;
		}

		return true;
	} else return false;
}

export function getLower() {}

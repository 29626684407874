import { Component, Input, OnInit } from '@angular/core';

export enum IMAGES_NAMES {
	logo_blue_gray = 'logo_blue_gray.svg',
	logo_circle_black = 'logo_circle_black.svg',
	logo_circle_blue = 'logo_circle_blue.svg',
	logo_circle_gray = 'logo_circle_gray.svg',
	logo_gray = 'logo_gray.svg',
	logo_lg_blue = 'logo_lg_blue.svg',
	logo_small_bg_black = 'logo_small_bg_black.svg',
	logo_small_bg_blue = 'logo_small_bg_blue.svg',
	logo_small_black = 'logo_small_black.svg',
	logo_small_blue = 'logo_small_blue.svg',
	logo_small_white = 'logo_small_white.svg',
	logo_square_blue_blue = 'logo_square_blue_blue.svg',
	logo_square_blue_gray = 'logo_square_blue_gray.svg',
	logo_white = 'logo_white.svg',
	logo_white_bg_blue = 'logo_white_bg_blue.svg',
	logo_white_bg_gray = 'logo_white_bg_gray.svg',
	logo_white_border = 'logo_white_border.svg',
	email = 'email.png',
	phone = 'phone.png',
	whatsapp = 'whatsapp.png',
}

@Component({
	selector: 'm-pic',
	template: ` <img [src]="src" [class]="class" [alt]="image" /> `,
	styles: [
		`
			img {
				max-width: 100% auto;
				max-height: 100% auto;
				width: 100%;
			}
		`,
	],
})
export class PicComponent implements OnInit {
	@Input() src: string = null;
	@Input() class: string = null;
	@Input() image: IMAGES_NAMES | string;

	constructor() {}

	ngOnInit(): void {
		if (!this.src && this.image) {
			this.src = `/assets/images/${IMAGES_NAMES[this.image]}`;
		} else {
		}
	}
}

import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
	constructor(translate: TranslateService, private primengConfig: PrimeNGConfig) {
		translate.setDefaultLang('es'); // this language will be used as a fallback when a translation isn't found in the current language
		translate.use('es'); // the lang to use, if the lang isn't available, it will use the current loader to get them
	}

	ngOnInit() {
		this.primengConfig.ripple = true;
	}
}

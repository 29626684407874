import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtml } from './safe-html';

const LIST = [
	SafeHtml
]

@NgModule({
  declarations: LIST,
  exports: LIST,
  imports: [
    CommonModule
  ]
})
export class UtilsModule { }

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category, ICategory } from '../classes/category';
import { IProduct, Product } from '../classes/product';
import { FirebaseService } from './firebase.service';

@Injectable({
	providedIn: 'root',
})
export class CategoriesService {
	private categories = [];

	constructor(private firebaseSrv: FirebaseService) {}

	public getCategoryById(id) {
		return this.categories.find((cat) => cat.id === id);
	}

	public getCategories$() {
		return this.firebaseSrv.getDoc(this.getDoc()).pipe(
			map((values) => {
				const categories = values?.categories || [];

				this.categories = categories;

				return categories.map((element) => new Category(element)) || categories;
			})
		);
	}

	public setCategories(categories) {
		return this.firebaseSrv.updateDoc(this.getDoc(), categories);
	}

	public saveCategory(category: Category) {
		const len = this.categories.length;
		const fun = !len ? 'addDoc' : 'updateDoc';

		this.categories.push(category.deserialize());

		return this.firebaseSrv[fun](this.getDoc(), { categories: this.categories });
	}

	public updateCategory(category: Category) {
		const i = this.categories.findIndex((item) => item.id === category.id);
		this.categories[i] = category.deserialize();

		return this.update();
	}

	public removeCategory(category) {
		const i = this.categories.findIndex((item) => item.id === category.id);
		this.categories.splice(i, 1);

		return this.update();
	}

	//////////////////////////////////////////////////////////////////////////////////////////////////////////
	public saveProduct(category: Category, pro: IProduct) {
		const product = new Product(pro);
		const i = this.categories.findIndex((item) => item.id === category.id);

		if (!this.categories[i].products) {
			this.categories[i].products = [];
		}

		this.categories[i].products.push(product.deserialize());

		return this.updateCategory(category);
	}

	public removeProduct(product) {
		const i = this.categories.findIndex((item) => item.id === product.category);
		const k = this.categories[i].products.findIndex((item) => item.id === product.id);

		this.categories[i].products.splice(k, 1);

		this.firebaseSrv.deleteImage(product.image);

		return this.update();
	}

	public addCategoryProduct(category: Category, pro: Product) {
		const product = new Product(pro);
		const i = this.categories.findIndex((item) => item.id === category.id);
		if (!this.categories[i].products) {
			this.categories[i].products = [];
		}
		this.categories[i].products.push(product.deserialize());

		return this.update();
	}

	public updateCategoryProducts(category: Category, product: Product) {
		const i = this.categories.findIndex((item) => item.id === category.id);
		const k = this.categories[i].products.findIndex((item) => item.id === product.id);

		this.categories[i].products[k] = product.deserialize();

		return this.update();
	}

	public updateProduct(pro: IProduct) {
		const product = new Product(pro);
		const i = this.categories.findIndex((item) => item.id === product.category);

		const k = this.categories[i].products?.findIndex((item) => item.id === product.id);

		this.categories[i].products[k] = product.deserialize();

		this.updateCategory(new Category(this.categories[i]));
	}

	private update() {
		return this.firebaseSrv.updateDoc(this.getDoc(), { categories: this.categories });
	}

	private getDoc() {
		return this.firebaseSrv.getDocByName('products');
	}
}

import { Component, OnInit } from '@angular/core';
import { COMPONENTS_ID } from 'src/app/constants';
import { InfoService } from 'src/app/services/info.service';

@Component({
	selector: 'm-who',
	templateUrl: 'who.component.html',
	styleUrls: ['./who.component.scss']
})
export class WhoComponent implements OnInit {

	id = COMPONENTS_ID['WHO'];

	info;

	constructor(
		private infoSrv: InfoService
	) {

	}

	ngOnInit(): void {
		this.infoSrv.getInfo().subscribe(values => {
			this.info = values;

		})
	}

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filter',
})
export class FilterPipe implements PipeTransform {
	public transform(array, properties) {
		if(!array || !array.length) return array;

		const keys = Object.keys(properties);
		const result = array.filter((item) => {
			return keys.some((k) => {
				return item[k] === properties[k];
			});
		});

		return result;
	}
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BREADCRUMBS_DATA } from './constants';
import { AuthGuardService } from './services/authguard.service';
import { ViewHomeComponent } from './views/view-home/view-home.component';

const routes: Routes = [
	{
		path: '',
		component: ViewHomeComponent,
	},
	{
		path: 'admin',
		canActivateChild: [AuthGuardService],
		loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
		data: {
			breadcrumbs: BREADCRUMBS_DATA.ADMIN,
		},
	},
	{
		loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
		path: 'login',
	},
	{ path: '**', redirectTo: '' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'top',
			anchorScrolling: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}

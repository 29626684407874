import { Injectable } from '@angular/core';
import { TOAST_TYPES } from '../components/toast/toast.component';
import { InjectComponentService } from './inject-component.service';

const TIME = 2000;

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	constructor(private resolver: InjectComponentService) {}

	public successMessage(title, subtitle?) {
		const data = {
			type: TOAST_TYPES.success,
			title,
			subtitle,
		};

		this.addSingle(data);
	}

	public errorMessage(title, subtitle?) {
		const data = {
			type: TOAST_TYPES.error,
			title,
			subtitle,
		};

		this.addSingle(data);
	}

	public infoMessage(title, subtitle?) {
		const data = {
			type: TOAST_TYPES.info,
			title,
			subtitle,
		};

		this.addSingle(data);
	}

	public warnMessage(title, subtitle?) {
		const data = {
			type: TOAST_TYPES.warning,
			title,
			subtitle,
		};

		this.addSingle(data);
	}

	private addSingle(data) {
		const time = 3000;
		const componentRef = this.resolver.show(
			'toast',
			Object.assign(data, {
				close: false,
			})
		);

		componentRef.instance['visible'] = true;
		setTimeout(() => {
			componentRef.instance['visible'] = false;
		}, time);
		setTimeout(() => {
			this.resolver.removeComponentFromBody(componentRef);
		}, time + 300);
	}

	private addMultiple() {
		// this.messageSrv.addA,
		// 	{ type: 'success', title: 'Service Message', 'Via MessageService' },
		// 	{ severity: 'info', title: 'Info Message', detail: 'Via MessageService' },
		// ]);
	}

	private clear() {
		// this.messageSrv.clear();
	}
}

<p-editor
	[placeholder]="placeholder"
	[ngStyle]="{ 'height.px': height }">
	<p-header>
		<span class="ql-format-group">
			<span title="Bold" class="ql-format-button ql-bold"></span>
			<span class="ql-format-separator"></span>
			<span title="Italic" class="ql-format-button ql-italic"></span>
			<span class="ql-format-separator"></span>
			<span title="Underline" class="ql-format-button ql-underline"></span>
			<span class="ql-format-separator"></span>
			<span title="Strikethrough" class="ql-format-button ql-strike"></span>
		</span>
	</p-header>
</p-editor>
<div class="main" [ngClass]="levels[level]">
	<div class="flex justify-content-between align-items-stretch align-content-center cursor-pointer header bg-light-gray pr-3 ">
		<div (click)="toggle = !toggle" class="flex-1 align-self-center pl-3" >
			<h4 class="mb-0">
				<span class="pi pi-angle-right" [ngClass]="{rotate: toggle}"></span>
				{{title}}
			</h4>
		</div>
		<div class="align-self-center">
			<ng-content select="[options]"></ng-content>
		</div>
	</div>
	<div *ngIf="toggle" [@slideIn]>
		<ng-content></ng-content>
		<div *ngIf="bottomClose" class="text-center cursor-pointer bg-light-gray shadow-4 mb-1" (click)="toggle=!toggle">
			<span class="pi pi-angle-up"></span>
		</div>
	</div>
</div>
<p-dialog
	class="cursor-move"
	[transitionOptions]="'250ms cubic-bezier(0, 0, 0.2, 1)'"
	[showHeader]="showHeader"
	[position]="position"
	[modal]="true"
	[keepInViewport]="false"
	[header]="header | translate"
	[focusTrap]="true"
	[focusOnShow]="true"
	[draggable]="true"
	[dismissableMask]="false"
	[closeOnEscape]="false"
	[closable]="closable"
	[(visible)]="visible"
	(onHide)="resolve()"
	>
	<div class="pt-3">
		{{body | translate}}
	</div>
	<ng-template pTemplate="footer" class="py-0 text-center" *ngIf="isBoolean" >
        <p-button icon="pi pi-check" (click)="resolve(true)" [label]="labelAccept | translate" ></p-button>
        <p-button icon="pi pi-times" (click)="resolve(false)" [label]="labelReject | translate" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>

<div class="py-3">
	<div id="image" *ngIf="src && src!==''; else imageForm" class="flex flex-column text-center">
		<p-image [src]="src" alt="Image" imageClass="max-w-full"></p-image>
		<div class="mt-2 flex justify-content-end ">
			<button pButton class="p-button-sm p-button-danger" [label]="'eliminar' | translate " (click)="reset()"></button>
			<!-- <button
				pButton
				(click)="subirArchivo()">
			Subir archivo
		</button> -->
		</div>
	</div>


	<ng-template #imageForm>
		<div>
			<form [formGroup]="form" (ngSubmit)="subirArchivo()">
				<label id="selector" for="upload"
					class="flex flex-column justify-content-center align-items-center cursor-pointer">
					<span class="pi pi-camera" aria-hidden="true"></span>
					<span class="pt-2"> {{'label.chooseImage' | translate}} </span>
				</label>
				<input
					type="file"
					id="upload"
					style="display:none"
					[formControlName]="field"
					(change)="cambioArchivo($event)">
				<p-progressBar *ngIf="porcentaje > 0 && porcentaje < 100" mode="indeterminate" [value]="porcentaje">
				</p-progressBar>
			</form>
		</div>
	</ng-template>
</div>
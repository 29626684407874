import { Component, OnInit } from '@angular/core';
import { WHATSAPP_URL } from 'src/app/constants';
import { InfoService } from 'src/app/services/info.service';

@Component({
	selector: 'm-bubble',
	templateUrl: './bubble.component.html',
	styleUrls: ['./bubble.component.scss'],
})
export class BubbleComponent implements OnInit {
	public bubbles;

	constructor(private infoSrv: InfoService) {}

	ngOnInit(): void {
		this.infoSrv.getInfo().subscribe((values) => {
			const { direct } = values;
			const { email, phone } = direct;
			let { whatsapp } = direct;
			whatsapp = /^34/.test(whatsapp) ? whatsapp : `34${whatsapp}`;

			const bubbles = [
				{
					id: 'phone',
					label: 'info.callPhone',
					image: 'phone',
					link: `tel:${phone}`,
					classes: 'block md:hidden',
					att: 'Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from flaticon.com',
				},
				{
					id: 'mail',
					label: 'info.sendMail',
					image: 'email',
					link: `mailto:${email}`,
					classes: '',
					att: 'Icons made by <a href="https://www.flaticon.com/authors/those-icons" title="Those Icons">Those Icons</a> from flaticon.com',
				},
				{
					id: 'whatsapp',
					label: 'info.writeWhatsapp',
					image: 'whatsapp',
					link: `${WHATSAPP_URL}/${whatsapp}`,
					classes: '',
					att: 'Iconos diseñados por <a href="https://www.flaticon.es/autores/pixel-perfect" title="Pixel perfect">Pixel perfect</a> from flaticon.es',
				},
			];

			this.bubbles = bubbles;
		});
	}

	openLink(bubble) {
		window.open(bubble.link, '_blank');
	}
}

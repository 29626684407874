import { trigger, state, style, transition, animate, animateChild, query } from '@angular/animations';
const T = 300;

export const SLIDE_IN_ANIMATION = trigger('slideIn', [
	state('*', style({ 'overflow-y': 'hidden' })),
	state('void', style({ 'overflow-y': 'hidden' })),
	transition('* => void', [style({ height: '*' }), animate(T, style({ height: 0 }))]),
	transition('void => *', [style({ height: '0' }), animate(T, style({ height: '*' }))]),
]);

export const SLIDE_IN_ANIMATION_2 = trigger('slideIn', [
	state(
		'*',
		style({
			transform: 'translateY(0) scale(1) rotateY(0)',
			opacity: 1,
			filter: 'blur(0) saturate(100%)',
		})
	),
	state(
		'void',
		style({
			transform: 'translateY(20px) scale(1.1) rotateY(5deg)',
			opacity: 0,
			filter: 'blur(2px) saturate(50%)',
		})
	),
	transition('void => *', animate('.3s ease-in-out')),
]);

export const SLIDE_OUT_ANIMATION_2 = trigger('slideOut', [
	state(
		'*',
		style({
			transform: 'translateX(0)  scale(1)',
			opacity: 1,
		})
	),
	state(
		'void',
		style({
			transform: 'translateX(100%) scale(.7)',
			opacity: 0,
		})
	),
	transition('* => void', animate('.2s ease')),
]);

export const triggerChildAnimation = trigger('triggerChildAnimation', [transition(':enter, :leave', [animate('0s'), query('*', [animateChild()])])]);

export const verticalCollapseAnimation = trigger('verticalCollapse', [
	state(
		'*',
		style({
			height: '*',
		})
	),
	state(
		'void',
		style({
			height: '0',
		})
	),
	transition('* => void', animate('.3s .3s ease')),
]);

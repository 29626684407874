import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Injector } from '@angular/core';

//PAGES
import { ViewHomeComponent } from './views/view-home/view-home.component';
import { SharedModule } from './shared.module';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthGuardService } from './services/authguard.service';

export class ServiceLocator {
	static injector: Injector;
}

const EXTENSOR_SERVICES: { [key: string]: { provide: any; deps: any[]; useClass?: any } } = {
	DynamicDialogRef: {
		provide: DynamicDialogRef,
		deps: [],
	},
	DynamicDialogConfig: {
		provide: DynamicDialogConfig,
		deps: [],
	},
};


@NgModule({
	declarations: [AppComponent, ViewHomeComponent],
	imports: [AppRoutingModule, BrowserModule, BrowserAnimationsModule, SharedModule],
	providers: [AuthGuardService],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor() {
		ServiceLocator.injector = Injector.create(
			Object.keys(EXTENSOR_SERVICES).map((key) => ({
				provide: EXTENSOR_SERVICES[key].provide,
				useClass: EXTENSOR_SERVICES[key].provide,
				deps: EXTENSOR_SERVICES[key].deps,
			}))
		);
	}
}

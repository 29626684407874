import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { COMPONENTS_ID } from 'src/app/constants';
import { CategoriesService } from 'src/app/services/categories.service';
import { SLIDE_IN_ANIMATION_2, SLIDE_OUT_ANIMATION_2, triggerChildAnimation, verticalCollapseAnimation } from 'src/app/utils/animations';
import { isResponsive } from 'src/app/utils/utils';
import { ProductDetailComponent } from '../product-detail/product-detail.component';

@Component({
	selector: 'm-products',
	templateUrl: 'products.component.html',
	styleUrls: ['./products.component.scss'],
	providers: [DialogService]
})
export class ProductsComponent implements OnInit {
	public id = COMPONENTS_ID['PRODUCTS'];
	public categories = [];
	public result = [];
	public selectedCategory;

	constructor(private categoriesSrv: CategoriesService,
		private dialogSrv: DialogService) {}

	ngOnInit(): void {
		this.categoriesSrv.getCategories$().subscribe((categories) => {
			const [first] = categories;
			this.categories = categories;
			this.selectedCategory = first;
		});
	}

	changeCategory(category) {
		this.selectedCategory = category;
	}


	openProduct(product){
		const component = ProductDetailComponent;

		this.dialogSrv.open(component, {
			data: { product },
			header: product.name,
			width: isResponsive() ? '100%' : '70%',
			height: isResponsive() ? '100%' : 'auto',
			transitionOptions: '400ms cubic-bezier(0.25, 0.8, 0.25, 1)'
		});
	}
}

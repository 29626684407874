<div
	id="item"
	class="h-full border-round w-full p-2">
	<div class="flex flex-column h-full w-full shadow-4" [ngClass]="cardClass">
		<div class="flex-grow-1 ">
			<div class="px-3 pt-3 pb-2" [ngClass]="headerClass">
				<h5 class="mb-0">
					{{title}}
				</h5>
				<small *ngIf="subtitle">
					{{subtitle}}
				</small>
			</div>
			<div class="py-2 px-3">
				<ng-content></ng-content>
			</div>
		</div>

		<div class="flex-grow-0">
			<ng-content select="[footer]"></ng-content>
		</div>
	</div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { SLIDE_IN_ANIMATION } from 'src/app/utils/animations';

const SPACE = 0;
@Component({
	selector: 'm-sticky-container',
	templateUrl: './sticky-container.component.html',
	styleUrls: ['./sticky-container.component.scss'],
	animations: [SLIDE_IN_ANIMATION],
})
export class StickyContainerComponent implements OnInit {
	@Input() bottomClose: boolean = false;
	@Input() margin: boolean = true;
	@Input() calculate: string = 'navbar';

	public top = 0;

	public toggle = false;

	constructor() {


	}

	ngOnInit(): void {
		const header: HTMLElement = document.querySelector(`#${this.calculate}`);
		this.top = ( header?.offsetTop + header?.offsetHeight ) + SPACE;
	}
}

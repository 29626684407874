<footer class="bg-light-gray mt-5 pt-5">
	<div class="container grid justify-content-end">
		<div class="col-6 text-center" >
			<ul style="list-style-type:none;">
				<li>{{name}}™</li>
			</ul>
		</div>
		<div class="col-6 text-right">
			<ul style="list-style-type:none;">
				<li>Aviso legal </li>
			</ul>
		</div>
	</div>
</footer>
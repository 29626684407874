import { Pipe } from "@angular/core";
import { DomSanitizer, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from "@angular/platform-browser";

@Pipe({ name: 'safeHtml' })
export class SafeHtml {
	constructor(private sanitizer: DomSanitizer) { }

	transform(value: any, type?: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
		if (!type) {
			return this.sanitizer.bypassSecurityTrustHtml(value);
		}

		switch (type) {
			case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
			case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
			case 'script': return this.sanitizer.bypassSecurityTrustScript(value);
			case 'url': return this.sanitizer.bypassSecurityTrustUrl(value);
			case 'resourceUrl': return this.sanitizer.bypassSecurityTrustResourceUrl(value);
			default: throw new Error(`Invalid safe type specified: ${type}`);
		}

	}
}
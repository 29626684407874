import { Component, Input} from '@angular/core';

@Component({
	selector: 'm-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
})
export class CardComponent {
	@Input() title: string;
	@Input() subtitle: string;
	@Input() description: string;
	@Input() cardClass: string | object;
	@Input() headerClass: string | object;

}

import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'm-editor',
	templateUrl: './editor.component.html',
	styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {

	@Input() placeholder;

	height = 100;

	constructor() { }

	ngOnInit(): void {
	}

}

<m-bubble></m-bubble>
<section id="home">
		<m-navbar></m-navbar>
		<m-banner-new></m-banner-new>
	<div class="my-6 py-3">
		<m-who #who></m-who>
	</div>
	<div class="my-6 py-3">
		<m-products #products></m-products>
	</div>
	<div class="mt-6 py-3">
		<m-where></m-where>
	</div>
	<div class="pt-3">
		<m-footer></m-footer>
	</div>
</section>
import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';

@Injectable({
	providedIn: 'root'
})
export class InfoService {

	constructor(
		private firebaseSrv: FirebaseService
	) { }

	public getInfo() {
		return this.firebaseSrv.getDoc(this.getDoc());
	}

	public setWho(who) {
		return this.firebaseSrv.updateDoc(this.getDoc(), who);
	}

	public setWhere(where) {
		return this.firebaseSrv.updateDoc(this.getDoc(), where);
	}

	public setLanguages(languages) {
		console.log('------', languages);
		return this.firebaseSrv.updateDoc(this.getDoc(), languages);
	}

	private getDoc() {
		return this.firebaseSrv.getDocByName('info');
	}

}

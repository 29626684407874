export interface IProduct {
	id: string;
	code: number;
	name: string;
	image: string;
	category: string;
	shortDescription?: string;
	description?: string;
	isVisible?: boolean;
}

export class Product {
	id: string;

	name: string;

	shortDescription: string;

	description: string;

	code: number;

	image: string;

	isVisible: boolean;

	category: string;

	constructor(product: IProduct | any) {
		const { isVisible } = product;

		Object.keys(product).forEach((k) => (this[k] = product[k]));

		this.isVisible = isVisible || false;
	}

	public deserialize(): {} {
		const element = this;

		return Object.keys(element).reduce((total, key) => {
			total[key] = element[key] === undefined ? null : element[key];
			return total;
		}, {});
	}
}

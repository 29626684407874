import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { first, map } from 'rxjs/operators';

import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import firebase from 'firebase';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class FirebaseService {
	private _user;

	constructor(
		private angularFirestore: AngularFirestore,
		private afAuth: AngularFireAuth,
		private firestorage: AngularFireStorage,
		private translateSrv: TranslateService,
		private afAnalytics: AngularFireAnalytics
	) {
		this.afAnalytics.setAnalyticsCollectionEnabled(environment.analytics.enabled);
		this.afAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {});
		this.getAuthState().then( val => {
			this.user = val;
		});
	}

	public get user() {
		return this._user;
	}

	public set user(value) {
		this._user = value;
	}
	//****************************************************/
	// ***************** AUTHENTICATION  *****************/
	//****************************************************/
	/**
	 *
	 * @param mail mail to login
	 * @param pass base64 password
	 */
	async login(mail: string, pass: string) {
		try {
			const response = await this.afAuth.signInWithEmailAndPassword(mail, pass);

			return this._user;
		} catch (error) {

		}
	}

	/**
	 *
	 */
	logout() {
		return this.afAuth.signOut();
	}

	createUser(mail: string, password) {
		return this.afAuth.createUserWithEmailAndPassword(mail, encodeURI(password));
	}

	resetPassword(mail: string) {
		return this.afAuth.sendPasswordResetEmail(mail);
	}

	async getAuthState() {
		try {
			const value = await this.afAuth.authState.pipe(first()).toPromise();
			if (value) {
				const email = value?.email || '@';
				const [user] = email.split('@');

				return {
					name: user,
					email,
				};
			} else {
				return null;
			}
		} catch (error) {
			//
		}
	}

	get authState$() :Observable<any> {
		return this.afAuth.authState;
	}

	isLogged$(): Observable<any> {
		return this.afAuth.authState.pipe(
			map((val) => {
				this._user = val.email;
				return val;
			})
		);
	}

	//****************************************************/
	//******************* FIRESTORE  *******************/
	//****************************************************/
	/**
	 *
	 */
	getDocByName(name: string, collection: AngularFirestoreCollection = this.getRootCollection()) {
		return collection.doc(name);
	}

	/**
	 *
	 */
	private getRootCollection() {
		const lang = this.translateSrv.currentLang || 'es';
		return this.angularFirestore.collection(lang);
	}

	/**
	 *
	 */
	public getCollection(collection: AngularFirestoreCollection) {
		return collection.valueChanges();
	}

	/**
	 * Create collection in root
	 *
	 * @param name createRootCollection
	 * @param doc
	 * @param data
	 */
	public createRootCollection(name, doc, data) {
		return this.angularFirestore.collection(name).doc(doc).set(data);
	}

	/**
	 * Create new doc
	 */
	public addDoc(doc: AngularFirestoreDocument, value: any) {
		return doc.set(value);
	}

	/**
	 *
	 */
	public getDoc(doc: AngularFirestoreDocument) {
		return doc.snapshotChanges().pipe(map((v) => v.payload.data()));
	}

	/**
	 *
	 */
	public updateDoc(doc: AngularFirestoreDocument, value: any) {
		return doc.update(value);
	}

	public setDoc(doc: AngularFirestoreDocument, value: any, merge: boolean = false) {
		return doc.set(value, { merge });
	}

	/**
	 *
	 */
	public deleteCollection() {}

	/**
	 *
	 */
	public deleteDoc(doc: AngularFirestoreDocument) {
		return doc.delete();
	}

	public getDocByEqual(collection: AngularFirestoreCollection, key: string, value: string) {
		return this.getParentCollection(collection)
			.collection(collection.ref.id, (ref) => ref.where(key, '==', value))
			.valueChanges();
	}

	private getParentCollection(collection: AngularFirestoreCollection) {
		const path = collection.ref.path.split('/');

		return path.reduce((final: AngularFirestoreCollection | AngularFirestoreDocument | any, curr, pos, array) => {
			if (pos === 0) {
				return this.angularFirestore.collection(curr);
			} else if (pos === array.length - 1) {
				return final;
			} else if (pos % 2 === 1) {
				return final.doc(curr);
			} else {
				return final.collection(curr);
			}
		}, '');
	}

	//****************************************************/
	//******************* FIRESTORAGE  *******************/
	//****************************************************/

	/**
	 * Tarea para subir archivo
	 * @param nombreArchivo
	 * @param datos
	 */
	public cloudStorageUpload(nombreArchivo: string, datos: any) {
		return this.firestorage.upload(nombreArchivo, datos);
	}

	/**
	 *  Referencia del archivo
	 * @param nombreArchivo
	 */
	public cloudStorageReference(nombreArchivo: string) {
		return this.firestorage.ref(nombreArchivo);
	}

	public deleteImage(downloadUrl) {
		return this.firestorage.storage.refFromURL(downloadUrl).delete();
	}
}

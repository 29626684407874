// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FirebaseService } from './firebase.service';
import { ToastService } from './toast.service';

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {
	constructor(private firebaseSrv: FirebaseService, private router: Router, private toastSrv: ToastService) {}

	// Access to lazy module
	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.firebaseSrv.getAuthState().then( (user: any) => {
			if(user){
				// this.toastSrv.successMessage(`Bienvenido ${user.name}`);
			} else {
				this.toastSrv.errorMessage(`No tienes permiso para acceder a la ruta`);
				this.router.navigate([]);
			}

			return !!user
		})


		// return !!this.firebaseSrv.authState$;
	}

	//Access to route
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.firebaseSrv.getAuthState().then( (value) => !!value);
	}

	private isAuthenticated() {
		// const user = this.firebaseSrv.user;

		// if (!user) {
		// 	this.toastSrv.errorMessage('No tienes permisos de acceso a esta sección');
		// 	this.router.navigate(['']);
		// }

		return this.firebaseSrv;
	}
}

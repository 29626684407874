import { IProduct, Product } from './product';

export interface ICategories {
	categories: ICategory[];
}

// XXXYYY Productos // XXX Categoria // YYY Producto
export interface ICategory {
	code: number;
	id: string;
	name: string;
	products: IProduct[];
	description?: string;
	isVisible: boolean;
}

export class Category {
	id: string;

	name: string;

	code: number;

	description: string;

	products: IProduct[] = [];

	isVisible: boolean;

	constructor(category: ICategory | any) {
		const { products = [], isVisible } = category;

		Object.keys(category).forEach((k) => (this[k] = category[k]));

		this.products = products.map((item) => new Product(item));
		this.isVisible = isVisible || false;
	}

	public deserialize(): {} {
		const element = this;

		return Object.keys(element).reduce((total, key) => {
			if (key === 'products') {
				total[key] = element[key].map((p: Product) => p.deserialize());
				return total;
			}
			total[key] = element[key] === undefined ? null : element[key];
			return total;
		}, {});
	}
}

<a class="hiddenLink" [id]="id"></a>
<div class="px-3">
	<div class="container bg-light-gray p-3 p-md-5 text-justify shadow-5">
		<m-spinner>
			<div class="grid justify-content-evenly align-items-center py-2" *ngIf="info">
				<div class="col-12 col-md-9 pr-0 pr-md-5">
					<h3 class="quicksand"> {{ info.name }} </h3>
					<div class="p-no-margin" [innerHTML]="info.description | html "></div>
				</div>
				<div class="col-6 offset-3 offset-md-0 col-md-3 pt-5 pt-md-0">
					<m-pic image="logo_small_bg_blue" class="shadow-5"></m-pic>
				</div>
			</div>
		</m-spinner>
	</div>
</div>
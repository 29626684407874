import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { InfoService } from 'src/app/services/info.service';

@Component({
	selector: 'app-view-home',
	templateUrl: 'view-home.component.html',
	styleUrls: ['./view-home.component.scss'],
})
export class ViewHomeComponent implements OnInit {
	public name;

	constructor(
			private router: Router,
			private dialogSrv: DialogService,
			private infoSrv: InfoService) {
		this.checkCookies();
		this.infoSrv.getInfo().subscribe( values => {
			this.name = values.shortName;
		})
	}

	checkCookies() {
		const cookies = document.cookie.split('; ').reduce((prev, current) => {
			const [name, ...value] = current.split('=');
			prev[name] = value.join('=');
			return prev;
		}, {});

		if (!cookies['cookies']) {
			this.dialogSrv.openSelectionDialog('Política de cookies', 'info.cookiesPolicy', 'bottom', 'label.accept', 'label.reject')
				.subscribe((value) => {
					if (value) {
						document.cookie = `cookies=${value}`;
					} else {
						history.back();
					}
			});
		}
	}

	ngOnInit(): void {}

	goTo() {
		this.router.navigate(['admin', 'login']);
	}
}

import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'm-product-detail',
	templateUrl: './product-detail.component.html',
	styleUrls: ['./product-detail.component.scss'],
	providers: [DialogService],
})
export class ProductDetailComponent implements OnInit {
	public product;

	private originalConfig;

	constructor(private config: DynamicDialogConfig, private dialogRef: DynamicDialogRef) {}

	ngOnInit(): void {
		const { product = {} } = this.config.data;

		this.originalConfig = { ...this.config } ;

		this.product= product;
	}

	closeDialog() {
		this.dialogRef.close();
	}

	showingImage(){
		this.config.height = '100%';

	}

	hidingImage(){
		this.config.height = this.originalConfig.height;
	}
}

<div class="stickyNav pt-2 shadow-4 w-full primary-bg" d="navbar">
	<nav class="px-md-0 px-3" i>
		<div class="container px-0">
			<div class="flex justify-content-between sm:justify-content-end align-items-end">
				<m-pic image="logo_small_white" class="md:hidden block" style="width: 2.5rem"></m-pic>
				<m-pic image="logo_white_border" class="md:block hidden" style="width: 13rem"></m-pic>
				<div>
					<button
						pButton
						pRipple
						type="button"
						icon="pi pi-bars"
						class="p-button-rounded p-button-text p-button md:hidden block text-white"
						styleClass="p-button"
						(click)="changeDisplay()"></button>
				</div>
				<div class="md:block hidden ">

					<button
					pButton
						pRipple
						*ngFor="let item of options"
						class="p-button-text text-white h-full quicksand"
						type="button"
						icon="pi"
						[label]="item.label | translate"
						(click)="navigate(item.id)"></button>
				</div>
			</div>
		</div>
	</nav>
</div>





<p-sidebar
	[(visible)]="display"
	[baseZIndex]="10000"
	position="right"
	[showCloseIcon]="false"
	[modal]="false"
	[style]="{ width: '55%'}">
	<div class="flex flex-column flex-wrap justify-content-between w-full h-full">
		<nav style="height:3rem"
			class="flex justify-content-between align-content-center shadow-4 w-full flex-grow-0 p-2 text-white primary-bg">
			<div></div>
			<div>
				<button
					pButton
					pRipple
					type="button"
					icon="pi pi-times"
					class=" p-button-rounded p-button-text p-button md:hidden block text-white"
					styleClass="p-button"
					(click)="changeDisplay()"></button>
			</div>
		</nav>
		<div class="flex-grow-1 p-3">
			<h3> Secciones </h3>
			<button
				*ngFor="let item of options"
				pButton
				pRipple
				class="p-button-text w-full text-left"
				type="button"
				[label]="item.label"
				(click)="navigate(item.id)"></button>
		</div>
		<div class="flex-grow-0">
			<footer class="text-center grid">
				<div class="col-6 col-offset-3 p-6">
					<m-pic image="logo_circle_blue"></m-pic>
				</div>
			</footer>
		</div>
	</div>
</p-sidebar>
export const GMAP_OPTIONS = {
	disableDefaultUI: true,
	disableDoubleClickZoom: true,
	clickableIcons: false,
	maxZoom: 18,
	minZoom: 6,
	noClear: false,
	stylers: [{ color: '#99FF33', visibility: 'off' }],
	mapTypeId: 'satellite',
};

export const COMPONENTS_ID = {
	WHERE: 'contact',
	WHO: 'about',
	PRODUCTS: 'products',
	LOGIN: 'login'
}

export const WHATSAPP_URL = 'https://wa.me';

export const BREADCRUMBS_DATA = {
	HOME: {
		icon: 'pi pi-home',
		routerLink: '/',
	},
	ADMIN: {
		label: 'label.admin',
		routerLink: '/admin',
	},
	ADMIN_HOME: {
		label: 'label.home',
		routerLink: '/admin',
	},
	PRODUCTS: {
		label: 'label.products',
		routerLink: '/admin/products',
	},
	WHERE: {
		label: 'label.contact',
		routerLink: '/admin/where',
	},
	WHO: {
		label: 'label.aboutUs',
		routerLink: '/admin/who',
	},
	LANGUAGES: {
		label: 'label.languages',
		routerLink: '/admin/languages',
	},
};

<div
	id="item"
	class="shadow-4 h-full border-round"
	[ngClass]="{'cursor-pointer': item.description}"
	(click)="item.description && openItem()"
	@slideIn
	@slideOut
	>
	<div class="flex flex-column justify-content-between align-content-center h-full">
		<div class="">
			<p-image
				[src]="item.thumbnail"
				[alt]="item.name"
				imageClass="max-w-full p-2">
			</p-image>

		</div>

		<div class=" mt-2">
			<div class="my-2 px-2 justify-self-top text-center">
				<h5 class="color-primary">
					{{item.name}}
				</h5>
				<p class="minHeight">
					{{item.shortDescription}}
				</p>
			</div>
			<div class="minHeight">

				<button
				*ngIf="item.description"
				pButton
				type="button"
				class="p-button-text w-full p-button-sm"
				[label]="'label.moreInfo' | translate"></button>
			</div>
		</div>
	</div>
</div>
import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'm-language-block',
	templateUrl: './language-block.component.html',
	styleUrls: ['./language-block.component.scss'],
})
export class LanguageBlockComponent implements OnInit {
	@Input() language;

	constructor() {}

	ngOnInit(): void {}
}
